<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="13">
        <!-- <el-card class="card-1">
          <div style="margin-bottom: 20px;font-size: 20px; font-weight: bold;">快捷入口</div>
          <div style="display: flex;justify-content: space-between;">
            <div v-for="(item, index) in entry" :key="index" style="text-align: center;" @click='to(item)'>
              <div style="width: 120px;height: 80px;background-color: #0f75ff;border-radius: 8px;text-align: center;">
                <img height="90%" :src="item.icon" />
              </div>
              <div style="margin-top: 10px;color: #409EFF;font-weight: 500;">{{ item.name }}</div>
            </div>
          </div>
        </el-card> -->
        <div>
          <!-- 统计卡片 -->
          <div class="card-2">
            <div class="card ft-bold">
              <!-- <i class="card-icon" :class="item.icon"></i> -->
              <span class="card-name ft-sz-12 c-white" style="backgroundColor: #245642;left: 20px;right: auto">用户名称</span>
              <div class="ft-sz-20" style="margin-top: 30px;text-align: center"><i class="el-icon-user-solid" style="margin-right: 10px"></i>{{name}}</div>
            </div>
            <div class="card ft-bold" v-for="(item, index) in cardData" :key="index">
              <!-- <i class="card-icon" :class="item.icon"></i> -->
              <span class="card-name ft-sz-12 c-white" :style="{ backgroundColor: item.color }">{{ item.name }}</span>
              <div class="ft-sz-30" style="margin-top: 0px">{{ item.data.num || 0 }}</div>
              <div class="card-trend ft-sz-14" style="color: #718096">
                较昨日
                <span :class="Number(item.data.ratio) > 0 ? 'up' : 'down'">
                  <i :class="Number(item.data.ratio) > 0 ? 'el-icon-top-right' : 'el-icon-bottom-right'"></i>
                  {{ Math.abs(Number(item.data.ratio) || 0) }}%
                </span>
              </div>
            </div>
          </div>
          <!-- 统计曲线图 -->
          <el-col style="padding: 0px">
            <!-- 统计卡片 -->
            <div class="card-5">
              <div class="echarts">
                <p>要素查询</p>
                <div id="myChart"></div>
              </div>
            </div>
          </el-col>
        </div>
      </el-col>
      <el-col :span="11">

        <el-card class="card-4">
          <div style="margin-bottom: 20px" >公告</div>

          <el-timeline :reverse="true">
            <el-timeline-item
              v-for="(activity, index) in notices"
              :key="index"
              :color="index==notices.length-1?'#50bfff':'#ccc'"
              :timestamp="activity.createdAt">
              {{activity.content}}
            </el-timeline-item>
          </el-timeline>

<!--          <vue-seamless-scroll :class-option="classOption" :data="notices" class="warp" >-->
<!--            <ul class="item">-->
<!--              <li v-for="(item, index) in notices" :key="index">-->
<!--                <span class="title" v-text="item.createdAt"></span>-->
<!--                <span class="date" v-text="item.content"></span>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </vue-seamless-scroll>-->

<!--          <el-carousel :interval='3000' height="100px" direction="vertical" :autoplay="true">-->
<!--            <el-carousel-item v-for="(item) in notices" :key="item">-->
<!--              <span>{{ item.createdAt }}</span>-->
<!--              <p>{{ item.content }}</p>-->
<!--            </el-carousel-item>-->
<!--          </el-carousel>-->
        </el-card>
        <el-card class="card-3" >
          <div style="margin-bottom: 20px;" >广告</div>

          <el-carousel :interval='2000' height="400px" direction="vertical" :autoplay="true" style="">
            <el-carousel-item v-for="item in ads" :key="item">
              <el-image
                style="width: 100%; height: 300px"
                :src="item.url"  :preview-src-list="[item.url]" ></el-image>
              <p>{{ item.content }}</p>
              <p>{{ item.createdAt }}</p>
            </el-carousel-item>
          </el-carousel>


        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20">

    </el-row>
  </div>
</template>

<script>
import service from "./api"
import echarts from "@/lib/echarts"
import dayjs from "dayjs"
import vueSeamlessScroll from 'vue-seamless-scroll'
// import ScrollingAnnouncementBar from './ScrollingAnnouncementBar.vue';
export default {
   components: {
     // vueSeamlessScroll
  },
  data () {
    return {
      classOption: {
        step: 2
      },
      ads:[],
      name:sessionStorage.getItem("merchant_name"),
      notices:[],
      entry: [
      { name: '消费统计', icon: require('../../assets/img/home/finance.png'), path: '/finance/purchase' },
      { name: '客户查询', icon: require('../../assets/img/home/blacklist.png'), path: '/risk' },
      // { name: '查询列表', icon: require('../../assets/img/home/element.png'), path: '' },
      { name: '账号管理', icon: require('../../assets/img/home/account.png'), path: '/userManager' }],
      cardData: [
        {
          name: "账户余额",
          icon: "el-icon-user",
          color: "#00bcd4",
          data: {
            num: 0,
            ratio: 0,
          }
        },
        {
          name: "今日查询",
          icon: "el-icon-view",
          color: "#f93b7a",
          data: {
            num: 0,
            ratio: 0,
          }
        },
        {
          name: "今日消费",
          icon: "el-icon-postcard",
          color: "#fbb624",
          data: {
            num: 0,
            ratio: 0,
          }
        },

      ],
    }
  },
  created () {
  },
  mounted() {
    this.getData();
    this.getNotice(1);
    this.getNotice(2);
  },
  computed: {
        //余额
    balance() {
      return this.$store.state.balance || 0;
    },
    // 卡片数据

  },
  methods: {
    getNotice(type){
       return service
        .getNotice({type:type})
        .then(res => {
          if(type == 1){
            this.ads = res;
            console.log('this.ads',this.ads)
          }else{
            this.notices = res;
            console.log('this.notices',this.notices)
          }

        });
    },
    to(item){
      this.$router.push({ path: item.path })
    },
    cardList (res) {
      console.log(res)
      this.cardData =  [
        {
          name: "账户余额",
          icon: "el-icon-user",
          color: "#00bcd4",
          data: {
            num: Number(res.amount/100).toFixed(2),
            ratio: Number(((res.amount - res.yesReduce)/(res.amount))-1).toFixed(2),
          },
        },
        {
          name: "今日查询",
          icon: "el-icon-view",
          color: "#f93b7a",
          data: {
            num: res.todayCount,
            ratio: Number((res.todayCount-res.yesCount)/res.yesCount).toFixed(2),
          },
        },
        {
          name: "今日消费",
          icon: "el-icon-postcard",
          color: "#fbb624",
          data: {
            num: Number(res.todayReduce/100).toFixed(2),
            ratio: Number((res.todayReduce-res.yesReduce)/100).toFixed(2),
          },
        },
      ];
    },
    getData() {
      return service.getData().then((res) => {
        this.cardList(res.datas)
        // 转换结构为 {'2020-01-01': {verify: 1, regist: 1}}
        let tmpStructRet = {};
        res.datas1.forEach((i) => (tmpStructRet[i.time] = i));
        // 生成 res 默认值
        let defaultData = {
          auditPassCount: 0,
          verifyCount: 0,
          pv: 0,
          regCount: 0,
          time: "",
        };
        // 补充 res 缺失的时间
        let fullRes = [];
        const today = dayjs(new Date());
        let day = dayjs(new Date()).subtract(15, "days");
        while (day.isBefore(today)) {
          day = day.add(1, "days");
          const dayStr = day.format("YYYY-MM-DD");
          if (tmpStructRet.hasOwnProperty(dayStr)) {
            fullRes.push(tmpStructRet[dayStr]);
          } else {
            defaultData.time = dayStr;
            fullRes.push(JSON.parse(JSON.stringify(defaultData)));
          }
        }
        // 生成折线图数据
        let charts = { date: [], verify: [], regist: [] };
        fullRes.forEach((item) => {
          charts.date.push(dayjs(item.time).format("MM-DD"));
            charts.verify.push(item.type0);

            charts.regist.push(item.type1);
        });
        this.drawLine(charts);
        // 计算上面四个值
        const todayDateStr = dayjs(new Date()).format("YYYY-MM-DD");
        const yesterdayDateStr = dayjs(new Date())
          .add(1, "days")
          .format("YYYY-MM-DD");
        const todayRes = tmpStructRet.hasOwnProperty(todayDateStr)
          ? tmpStructRet[todayDateStr]
          : defaultData;
        const yesterdayRes = tmpStructRet.hasOwnProperty(yesterdayDateStr)
          ? tmpStructRet[yesterdayDateStr]
          : defaultData;

        // this.cardData = {
        //   pageView: {
        //     num: todayRes.pv,
        //     ratio: Math.min(
        //       Math.ceil((todayRes.pv / (yesterdayRes.pv || 1)) * 100),
        //       100
        //     ),
        //   },
        //   register: {
        //     num: todayRes.regCount,
        //     ratio: Math.min(
        //       Math.ceil(
        //         (todayRes.regCount / (yesterdayRes.regCount || 1)) * 100
        //       ),
        //       100
        //     ),
        //   },
        //   certification: {
        //     num: todayRes.verifyCount,
        //     ratio: Math.min(
        //       Math.ceil(
        //         (todayRes.verifyCount / (yesterdayRes.verifyCount || 1)) * 100
        //       ),
        //       100
        //     ),
        //   },
        //   through: {
        //     num: todayRes.auditPassCount,
        //     ratio: Math.min(
        //       Math.ceil(
        //         (todayRes.auditPassCount / (yesterdayRes.auditPassCount || 1)) *
        //           100
        //       ),
        //       100
        //     ),
        //   },
        // };
      });
    },
    // getContact(){
    //   return service.getContact().then(res => {
    //     this.wechat = res.sponsoredUserWechat;
    //     this.imageUrl = res.sponsoredUserWechatQrcode ? base +'api/common/attach/'+res.sponsoredUserWechatQrcode : ''
    //   })
    // },
    // 绘画曲线图
    drawLine(charts) {
      // 基于准备好的dom，初始化echarts实例

      let myChart = echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        color: ["#00bcd4", "#7043c1"],
        tooltip: { trigger: "item" },
        legend: {
          data: ["二要素", "三要素"],
          icon: "circle",
          top: "bottom",
          color: "#000000",
          inactiveColor: "#555555",
        },
        xAxis: [{ type: "category", data: charts.date }],
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            splitLine: { show: false },
            axisLine: { show: false },
          },
        ],
        series: [
          {
            name: "二要素",
            type: "line",
            data: charts.verify,
            lineStyle: { width: 4 },
            itemStyle: { normal: { borderWidth: 4, borderColor: "#A9F387" } },
            areaStyle: { color: "#00bcd4" },
            smooth: true,
          },
          {
            name: "三要素",
            type: "line",
            data: charts.regist,
            lineStyle: { width: 4 },
            itemStyle: { normal: { borderWidth: 4, borderColor: "#f93b7a" } },
            areaStyle: { color: "#7043c1" },
            smooth: true,
          },
        ],
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.card-2{
  display: flex;
  justify-content: space-between;
  //margin-top: 20px;
  margin-bottom: 20px;
}
.card-3{
  margin-bottom: 20px;
  padding: 10px 10px 10px 0px;
}
.card-4{
  margin-bottom: 20px;
  padding: 10px 10px 10px 0px;
}
.b_title{
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
}
.card {
  background: #ffffff;
  width: 23%;
  min-height: 36px;
  border-radius: 4px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06),
    0 1px 0 0 rgba(0, 0, 0, 0.02);
  padding: 20px;
  position: relative;

  &-icon {
    color: #e2e2f1;
    font-size: 24px;
    font-weight: 700;
  }

  &-name {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 4px 6px;
    border-radius: 3px;
  }

  &-trend {
    margin-top: 10px;

    .up {
      color: #1ecab8 !important;
    }

    .down {
      color: #f93b7a !important;
    }
  }
}
.echarts {
    padding: 20px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 20px;
    #myChart {
      width: 100%;
      height: 350px;
    }
  }

.warp {
  margin: 0 auto;
  overflow: hidden;
  margin: 0 auto;
  overflow: hidden;
  ul {
    list-style: none;
    padding: 0;
    height: 100px;
    margin: 0 auto;
    li,
    a {
      display: block;
      display: flex;
      justify-content: space-between;
      font-size: 15px;
    }
  }
}
// .el-carousel__item h3 {
//   color: #475669;
//   font-size: 14px;
//   opacity: 0.75;
//   line-height: 300px;
//   margin: 0;
// }

// .el-carousel__item:nth-child(2n) {
//   background-color: #99a9bf;
// }

// .el-carousel__item:nth-child(2n+1) {
//   background-color: #d3dce6;
// }
</style>
